import React from 'react'

export const AccordionData = [
  {
    heading: "HTML,CSS",
    content1: <a href='https://coco-factory.jp/ugokuweb/' target='_blank' rel='noopener noreferrer'>動くWebデザイン
      アイディア帳さん</a>
  },
  {
    heading: "React",
    content1: <a href='https://onebitious.net/' target='_blank' rel='noopener noreferrer'>onebitious`s BLOGさん</a>,
    memo: "Reactにハンバーガーメニューを実装してみる。Reactにアコーディオンメニューを実装してみる。",
    content2: <a href="https://youtu.be/AUaWCCl5JXk?si=R7KsfYQxamurdzhF" target='_blank' rel='noopener noreferrer'>【とらゼミ】トラハックのエンジニア学習講座さん</a>
  },
  {
    heading: "画像",
    content1: <a href='https://icon-rainbow.com/' target='_blank' rel='noopener noreferrer'>icon-rainbowさん</a>
  },
]
