import React from 'react'
import twitter from '../../images/twitter.png'

const Contact = () => {
  return (
    <div id='contact' className='contact'>
      <h1>Contact</h1>
      <div className='contactWrapper'>
        <img src={twitter} alt='コンタクト画像' />
        <p>
          <span><a href='https://twitter.com/komaichan918' target='_blank' rel="noopener noreferrer">@komaichan918</a></span>
        </p>
      </div>
    </div>
  )
}

export default Contact
