import React from 'react'
import about from '../../images/pcicon.png'

const About = () => {
    return (
        <div id='about' className='about'>
            <h1>About</h1>
            <div className='profile'>
                <div className='profileText'>
                    <p>NAME：</p>
                    <p>もりもとみき</p>
                </div>
                <div className='profileText'>
                    <p>BIRTH：</p>
                    <p>1995／09／18</p>
                </div>
                <div className='profileText'>
                    <p>OTHER：</p>
                    <p>北海道生まれ、札幌学院大学人文学部臨床心理学科中途退学<br />WEB制作がしたくて上京したので現在転職活動中・ポートフォリオ鋭意制作中です</p>
                </div>
            </div>
            <div className='aboutWrapper'>
                <img src={about} alt='about画像' />
                <span>
                    <div className='aboutText'>
                        <p>使ってきた言語</p>
                        <h2>HTML/CSS/jQuery/React/PHP/Laravel</h2>
                    </div>
                    <div className='aboutText'>
                        <p>使ってきたツール</p>
                        <h2>VScode/Figma/Soucetree/Backlog</h2>
                    </div>
                    <div className='aboutTextMedia'>
                        <p>使ってきた言語</p>
                        <h2>HTML/CSS/jQuery/React
                            PHP/Laravel</h2>
                    </div>
                    <div className='aboutTextMedia'>
                        <p>使ってきたツール</p>
                        <h2>VScode/Figma
                            Soucetree/Backlog</h2>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default About
