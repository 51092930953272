import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionData } from './AccordionData'

// import '../css/accordion.scss';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const accordion = () => {
  return (
    <Accordion allowZeroExpanded>
      {AccordionData.map((value, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {value.heading}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className='accordionContents'>
              {value.content1}
              <span className='memo'>{value.memo}</span>
            </div>
            <div className='accordionContents'>
              {value.content2}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default accordion
