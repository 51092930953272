import React from 'react'
// import { Link } from 'react-router-dom';
import { Accordion } from '../../../components/topCompornents/index'

const Others = () => {
  return (
    <div id='others' className='contentsWrap'>
      <h1>Others</h1>
      <div className='othersWrapper'>
        {/* <div className='game'>
          <h3><Link to="/Chat">＞お遊びリンク＜</Link></h3>
        </div> */}
        <h3>ポートフォリオ制作参考リンク</h3>
        <Accordion />
      </div>
    </div>
  )
}

export default Others
