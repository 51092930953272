import React, { useEffect, useState } from 'react'
import { ProductData } from './ProductData'
import { SampleData } from './SampleData'
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Products = () => {

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const controls = useAnimation();
  const controls2 = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [inView, controls]);
  useEffect(() => {
    if (inView2) {
      controls2.start({ opacity: 1, y: 0 });
    }
  }, [inView2, controls2]);

  return (
    <div id='product' className='contentsWrap'>
      <h1>Products</h1>
      <h2>制作物サンプル</h2>
      <p>クリックでサイトのデモが確認できます</p>
      <motion.div
        className='productsWrapper'
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
        transition={{ duration: 1 }}
      >
        {SampleData.map((value, index) => {
          const isHovered = index === hoveredIndex;

          return (
            <li className='product' key={index}>
              <div
                className='productIcon-sample'
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {value.icon}
                {isHovered &&
                  <a href={value.link} title="">
                    <div className='productText'>
                      <p>{value.text}</p>
                    </div>
                  </a>
                }
              </div>
              <p>{value.title}</p>
              <p>{value.data}</p>
            </li>
          )
        })}
      </motion.div>
      <h2>社内研修</h2>
      <motion.div
        className='productsWrapper'
        ref={ref2}
        initial={{ opacity: 0, y: 20 }}
        animate={controls2}
        transition={{ duration: 1 }}
      >
        {ProductData.map((value, index) => {
          const isHovered = index === hoveredIndex;

          return (
            <li className='product' key={index}>
              <div
                className='productIcon'
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {value.icon}
                {isHovered &&
                  <Link to={value.link}>
                    <div className='productText'>
                      <p>{value.text}</p>
                    </div>
                  </Link>
                }
              </div>
              <p>{value.title}</p>
              <p>{value.data}</p>
            </li>
          )
        })}
      </motion.div>
    </div>
  )
}

export default Products
