import React, { useEffect, useState } from 'react'
import scroll from '../images/scroll.png'

const Comp = () => {
    // 表示切り替え
    const [showScrollToTop, setshowScrollToTop] = useState(false);

    // スクロールイベント
    useEffect(() => {
        window.addEventListener("scroll", watchScroll);
        return () => {
            window.removeEventListener("scroll", watchScroll);
        };
    }, []);

    const watchScroll = () => {
        const basePosition = 400;
        const scrollPosition = window.scrollY;
        setshowScrollToTop(basePosition <= scrollPosition);
    };

    return (
        <div className='scrollEvent'>
            {showScrollToTop && (
                <span
                    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    className='scroll-to-top'
                >
                <img src={scroll} alt='上へ' />
                </span>
            )}
        </div>
    );
};

export default Comp