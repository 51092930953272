import React from 'react';
// スワイパーのインポート
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// ページの矢印、下の〇ナビ、自動再生
import { Link } from "react-router-dom";

import arrow from '../../../../images/矢印.png'
import img1 from '../../../../images/product/wordpress.png';

const Wordpress = () => {

  return (
    <div>

      <div className="work">
        <span className='top'>
          <Link to="/">TOP</Link>
        </span>

        <div className="slideshow-container">
          <Swiper
            speed={1000}
            slidesPerView={1}
            // 1回のスライドで表示する画像の数
            spaceBetween={30}
            // スライド同士の隙間
            loop={true}
            // 画像のループ
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            //　自動再生の秒数
            pagination={{
              clickable: true,
            }}
            // 矢印クリックでスライド
            navigation={true}

            // 下の丸の表示
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide><img src={img1} alt='画像1' /></SwiperSlide>
            <SwiperSlide><img src={img1} alt='画像1' /></SwiperSlide>
          </Swiper>
        </div>

        <div className="workMain">
          <div className="workText">
            <h3>WordPress</h3>
            <p>制作期間：1カ月</p>
            <p>使用ツール：WordPress</p>
            <p>概要：WordPressでの初制作。</p>
            <p>社内研修をすべて終えた後、自己学習を進めるにあたってさすがに触っておいた方が良いと教えてもらったWordPress。ここで初めてドメインの作成や、レンタルサーバーを契約しました。</p>
            <p>しかし、このポートフォリオサイトをメインのドメインに設定した結果、WordPressのドメインがずれてアクセス不可になったため現在改修中。</p>
          </div>
          <div className='page'>
            <span className='back'>
              <Link to="/Travel">
                <img src={arrow} alt='前' />
                <p>Back</p>
              </Link>
            </span>
            <span className='noNext'>
              <p>Next</p>
              <img src={arrow} alt='後' />
            </span>
          </div>
        </div>
      </div>
    </div >
  )
}
export default Wordpress
