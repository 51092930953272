import React from "react";

const Chat = () => {
  return (
    <div>
      <p>Reactお遊びページ</p>
    </div>
  )
}

export default Chat
