import React from 'react';
// スワイパーのインポート
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// ページの矢印、下の〇ナビ、自動再生
import { Link } from "react-router-dom";

import arrow from '../../../../images/矢印.png'
import img1 from '../../../../images/product/macaron1.png';
import img2 from '../../../../images/product/macaron2.PNG';
import img3 from '../../../../images/product/macaron3.PNG';

const Macaron = () => {

  return (
    <div>

      <div className="work">
        <span className='top'>
          <Link to="/">TOP</Link>
        </span>

        <div className="slideshow-container">
          <Swiper
            speed={1000}
            slidesPerView={1}
            // 1回のスライドで表示する画像の数
            spaceBetween={30}
            // スライド同士の隙間
            loop={true}
            // 画像のループ
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            //　自動再生の秒数
            pagination={{
              clickable: true,
            }}
            // 矢印クリックでスライド
            navigation={true}

            // 下の丸の表示
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide><img src={img1} alt='画像1' /></SwiperSlide>
            <SwiperSlide><img src={img2} alt='画像2' /></SwiperSlide>
            <SwiperSlide><img src={img3} alt='画像3' /></SwiperSlide>
          </Swiper>
        </div>

        <div className="workMain">
          <div className="workText">
            <h3>マカロンのECサイト</h3>
            <p>制作期間：2カ月</p>
            <p>使用ツール：HTML、CSS、jQuery</p>
            <p>概要：入社した会社で社内研修として第1作目に作ったサイト。</p>
            <p>タブの切り替えや画像のスライド機能をjQueryで作りました。</p>
          </div>
          <div className='page'>
            <span className='noBack'>
              <img src={arrow} alt='前' />
              <p>Back</p>
            </span>
            <span className='next'>
              <Link to="/Sns">
                <p>Next</p>
                <img src={arrow} alt='後' />
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div >
  )
}
export default Macaron
