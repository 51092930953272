import React from 'react'
import flower from '../../../images/product/お花屋さん.png';
import hospital from '../../../images/product/病院.png';
import makeup from '../../../images/product/お化粧.png';

export const SampleData = [
  {
    title: "サンプル1",
    icon: <img src={flower} alt='sample-flower' />,
    data: "2023/10",
    text: "HTML/CSS/JavaScript/Figma",
    link: "https://komaichan.site/sample_LP/flower/index.html"
  },
  {
    title: "サンプル2",
    icon: <img src={hospital} alt='sample-hospital' />,
    data: "2023/10",
    text: "HTML/CSS/JavaScript/Figma",
    link: "https://komaichan.site/sample_LP/hospital/index.html"
  },
  {
    title: "サンプル3",
    icon: <img src={makeup} alt='sample-makeup' />,
    data: "2023/10",
    text: "HTML/CSS/JavaScript/Figma",
    link: "https://komaichan.site/sample_LP/makeup/index.html"
  },
];
