import React from 'react'
import Macaron from '../../../images/product/macaron1.png';
import Sns from '../../../images/product/SNS1.png';
import Travel from '../../../images/product/Travel1.png';
import Wordpress from '../../../images/product/wordpress.png';

export const ProductData = [
    {
        title: "マカロンサイト",
        icon: <img src={Macaron} alt='マカロンサイト' />,
        data: "2022/5",
        text: "HTML/CSS/jQuery",
        link: "/Macaron"
    },
    {
        title: "SNS",
        icon: <img src={Sns} alt='SNS' />,
        data: "2022/11",
        text: "HTML/CSS/jQuery/PHP(Laravel)",
        link: "/Sns"
    },
    {
        title: "宿泊予約サイト",
        icon: <img src={Travel} alt='宿泊予約サイト' />,
        data: "2023/4",
        text: "HTML/CSS/jQuery/React/PHP(Laravel)",
        link: "/Travel"
    },
    {
        title: "Wordpress",
        icon: <img src={Wordpress} alt='Wordpress' />,
        data: "2023/4",
        text: "WordPress",
        link: "/Wordpress"
    }
];
