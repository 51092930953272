import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import top from '../../src/images/top.jpg'

const Header = () => {
    // 表示切り替え
    const location = useLocation();
    const [showScrollToTop, setshowScrollToTop] = useState(false);

    // スクロールイベント
    useEffect(() => {
        if (location.pathname === '/') {
            const basePosition = window.innerHeight;

            const watchScroll = () => {
                const scrollPosition = window.scrollY;
                setshowScrollToTop(basePosition <= scrollPosition);
            };


            window.addEventListener("scroll", watchScroll);
            return () => {
                window.removeEventListener("scroll", watchScroll);
            };
        }
    }, [location]);

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <header>
            {showScrollToTop && (
                <span className='header'>
                    <div
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className='logo'>
                        <a href='/'><img src={top} alt='トップ画像' /></a>
                    </div>

                    <div className='burger'>
                        <div className={`burgerMenu ${isOpen ? 'close' : ''}`} onClick={toggleMenu}>
                            <span></span>
                            <p>menu</p>
                            <span></span>
                        </div>
                        <nav className={`menu ${isOpen ? 'menuOpen' : 'menuClose'}`}>
                            <ul>
                                <li>
                                    <a href="#about" onClick={closeMenu}>About</a>
                                </li>
                                <li>
                                    <a href="#product" onClick={closeMenu}>Product</a>
                                </li>
                                <li>
                                    <a href="#others" onClick={closeMenu}>Others</a>
                                </li>
                                <li>
                                    <Link to="https://www.blog.komaichan.site" onClick={closeMenu}>Blog</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <nav className='menu'>
                        <ul>
                            <li>
                                <a href="#about">About</a>
                            </li>
                            <li>
                                <a href="#product">Product</a>
                            </li>
                            <li>
                                <a href="#others">Others</a>
                            </li>
                            <li>
                                <Link to="https://www.blog.komaichan.site">Blog</Link>
                            </li>
                        </ul>
                    </nav>
                </span>
            )}
        </header>
    )
}

export default Header;
