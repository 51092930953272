import React from 'react'
import Comp from './Comp'
import top from '../../src/images/top.jpg'
import { About, Product, Others, Contact } from '../components/topCompornents/index'

const Homepage = () => {
  return (
    <div className='top'>
      <div className='topBack'>
        <div className='topBox'>
          <span className='topImg'>
            <img src={top} alt='トップ画像' />
          </span>
          <ul className='topLink'>
            <li><a href="#about">ABOUT</a></li>
            <li><a href="#product">PRODUCT</a></li>
            <li><a href="#others">OTHERS</a></li>
            <li><a href="#contact">CONTACT</a></li>
          </ul>
        </div>
        <div className='scrollDawn'>
          <span className='vertical'></span>
          <span className='diagonal'></span>
          <p>scroll</p>
        </div>
      </div>
      <Comp />
      <div className='contents'>
        <About />
        <Product />
        <Others />
        <Contact />
      </div>
    </div>
  )
}

export default Homepage
