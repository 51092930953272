import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import BlogPage from './components/BlogPage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Macaron from './components/topCompornents/Products/work/Macaron';
import Sns from './components/topCompornents/Products/work/Sns';
import Travel from './components/topCompornents/Products/work/Travel';
import Wordpress from './components/topCompornents/Products/work/Wordpress';
import Chat from './components/topCompornents/others/Chat';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/Macaron" element={<Macaron />} />
          <Route path="/Sns" element={<Sns />} />
          <Route path="/Travel" element={<Travel />} />
          <Route path="/Wordpress" element={<Wordpress />} />
          <Route path="/Chat" element={<Chat />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
